import React, { Component } from "react";
import Home from "./Home";
import CreditAgreement from "./Page/CreditAgreement";
import PrivacyAgreement from "./Page/PrivacyAgreement";
import ServiceAgreement from "./Page/ServiceAgreement";
import PushInfo from "./Page/PushInfo";
import TestAmount from "./Page/TestAmount";
import ProV1 from "./Page/langding/proV1";
import demoTest from "./Page/demo/test";
import testPackage from "./Page/demo/testPackage";
import TestUserAgent from "./Page/demo/TestUserAgent";
import TestWLAmount from "./Page/TestWLAmount";
import FAQ from "./Home/FAQ";


import { Router, Route } from "react-router-dom";

import { createBrowserHistory } from "history";
const browserHistory = createBrowserHistory();

class App extends Component {
  componentDidMount() {
    if (window.location.href.indexOf("/#/testAmount") > -1) {
      window.location.href = "/testAmount";
    }

    if (window.location.href.indexOf("/#/third") > -1) {
      window.location.href = window.location.href.replace(
        "/#/third",
        "/thirdInfo"
      );
    }
    if (window.location.href.indexOf("/#/creditAgreement") > -1) {
      window.location.href = "/creditAgreement";
    }
    if (window.location.href.indexOf("/#/privacyAgreement") > -1) {
      window.location.href = "/privacyAgreement";
    }
    if (window.location.href.indexOf("/#/serviceAgreement") > -1) {
      window.location.href = "/serviceAgreement";
    }





   
  }
  render() {
    console.log("----App----");
    let testUserAgent = localStorage.getItem('testUserAgent')
    return (
      <Router history={browserHistory}>
        <Route exact path="/" component={Home} />
        <Route exact path="/creditAgreement" component={CreditAgreement} />
        <Route exact path="/privacyAgreement" component={PrivacyAgreement} />
        <Route exact path="/serviceAgreement" component={ServiceAgreement} />
        <Route exact path="/thirdInfo" component={PushInfo} />
        <Route exact path="/testAmount" component={TestAmount} />
        <Route exact path="/testAmount1" component={TestAmount} />
        <Route exact path="/testAmount2" component={TestAmount} />
        <Route exact path="/testAmount3" component={TestAmount} />
        <Route exact path="/testAmount4" component={TestAmount} />
        <Route exact path="/testAmount5" component={TestAmount} />
        <Route exact path="/testWLAmount" component={TestWLAmount} />
        <Route exact path="/proV1" component={ProV1} />
        <Route exact path="/proV2" component={ProV1} />
        <Route exact path="/proV3" component={ProV1} />
        <Route exact path="/proV4" component={ProV1} />
        <Route exact path="/proV5" component={ProV1} />
        <Route exact path="/testService" component={demoTest} />
        <Route exact path="/testPackage" component={testPackage} />
        {testUserAgent && <Route exact path="/testUserAgent" component={TestUserAgent} />}
        <Route exact path="/faq" component={FAQ} />
      </Router>
    );
  }
}

export default App;
