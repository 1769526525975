import React from "react";
import "./static/partners.less";
export const Partners = ({isMobile}) => {
  // 动态加载图片
  const importAllImages = (requireContext) => {
    return requireContext.keys().map(requireContext);
  };

  // 加载 images 文件夹中的所有图片
  const images = importAllImages(
    require.context("../assets/imgs/partners", false, /\.(png|jpe?g|svg)$/)
  );

  return (
    <div className={isMobile ? "partners-container-mobile" : "partners-container"}>
      <div className="partners-main">
        <div className="title-selection">
        <p className="title">
          <span className="large">Partners(Repayment Methods)</span>
        </p>
        <div className="color-area"/>
        <p className="desc">
          {/* Support payment centers, e- wallets, offline stores and other payment
          ways */}
          We partner with top financial institutions to offer professional, secure online credit services.
        </p>
        </div>
        <div className="selection-partners">
          {images.map((image, index) => (
            <img
              key={index}
              src={image.default || image}
              alt={`Recognition ${index + 1}`}
              className="partners-image"
            />
          ))}
           {!isMobile ? images.map((image, index) => (
            <img
              key={index}
              src={image.default || image}
              alt={`Recognition ${index + 1}`}
              className="partners-image"
            />
          )) : ""}
        </div>
      </div>
    </div>
  );
};
