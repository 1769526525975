import React from 'react';
// import { Icon } from 'antd';
import './static/footer.less'
import msgIcon from "../assets/imgs/home/footer/xinxi-7@2x.png"
import phoneIcon from "../assets/imgs/home/footer/组 4471@2x.png"
import clock from "../assets/imgs/home/footer/组 4472@2x.png"
import faceBookIcon from "../assets/imgs/home/footer/组 4473@2x.png"
// import xIcon from "../assets/imgs/home/footer/组 4474@2x.png"
// import youtibeIcon from "../assets/imgs/home/footer/组 4475@2x.png"
import logo from "../assets/imgs/home/footer/组 4443@2x.png"
import downloadIcon from "../assets/imgs/home/footer/下载@2x.png"
import { downloadClick } from '../utils/common';
const Footer = ({isMobile}) => {
  return (
    <div>
      <div className={isMobile? "footer-mobile" : "footer"}>
        <div className="area">
          <div className="footer-section">
            <div className="customer">
              <h3>Customer Service</h3>
              <div className="customer-selection">
                <img class="icon" src={msgIcon} alt="msgIcon" />
                <span className="text">service@okpeso.com</span>
              </div>
              {/* <div className="customer-selection">
                <img class="icon" src={phoneIcon} />
                <span className="text">0063-9052553180</span>
              </div> */}
              <div className="customer-selection">
                <img class="icon" src={phoneIcon} alt="phoneIcon" />
                <span className="text">Smart：09319533595(WhatsApp)</span>
              </div>
              <div className="customer-selection">
                <img class="icon" src={phoneIcon} alt="phoneIcon" />
                <span className="text">Viber ：09621546377</span>
              </div>
              <div className="customer-selection">
                <img class="icon" src={clock}  alt="clockIcon"/>
                <span className="text">{/* From Monday to Sunday  */}9:00 AM to 6:00 PM</span>
              </div>
            </div>
          </div>
          <div class="social">
            {!isMobile? <h3>We are in social networks:</h3> : ""}
            <div className="social-links">
              <a
                href="https://www.facebook.com/OKPesoHub"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={faceBookIcon} alt="faceBookIcon" />
              </a>
              {/* <a
                href="https://www.twitter.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={xIcon} alt="xIcon" /> 
              </a>
              <a
                href="https://www.youtube.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={youtibeIcon} alt="youtobeIcon" />
              </a> */}
            </div>
          </div>
          {!isMobile? (<div className="logo">
            <img className="logoImg" src={logo} alt="logo" />
            <div className="footer area download-btn" onClick={downloadClick}>
              <div className="content">
                <img class="icon" src={downloadIcon} alt="downloadIcon" />
                <div class="text">
                  <span class="large-text">OFFICIAL OKPeso APK</span>
                </div>
              </div>
            </div>
          </div>) :""}
        </div>
      {isMobile ? <button className="mobile-donwload-button" onClick={downloadClick}>DOWNLOAD NOW</button> : ""}
      </div>
      
       {/* <hr class="centered-line"/> */}
       <div className={isMobile? "company-mobile" : "company"}>
          <p>
            SEC Registrstion : NO.CS201913681 | Certificate Of Authority: NO.3059
          </p>
        </div>
    </div>
  );
};

export default Footer;