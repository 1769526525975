import React, {useState,useEffect} from 'react';
// import QueueAnim from 'rc-queue-anim';
// import { Row } from 'antd';
import { faq } from './data';
// import faqBgImg from '../assets/imgs/website-faqBg.png';
import faqBgImg2 from '../assets/imgs/website-faqBg2.png';
import faqBgImg3 from '../assets/imgs/website-faqBg3.png';
import faqBgImg4 from '../assets/imgs/website-faqBg4.png';
// import { HashRouter as Router, Link } from "react-router-dom";
import Footer from './Footer'
import Header from './Header';
import { homeJumpToSection } from '../utils/common';
import { enquireScreen } from "enquire-js";


export default function FAQ(props) {
  
  const [isMobile, setIsMobile] = useState(false)
  useEffect(() => {
    const handler = (b) => setIsMobile(!!b);
    enquireScreen(handler);
    return () => {
      enquireScreen.unregister(handler);
    };
  }, []); 

  const [currentTab, setCurrentTab] = useState(0)

  // const renderDesc = (descriptions) => {
  //   const descHtml= descriptions.map((item, i) => (
  //     <p key={i.toString()}>
  //       {item}
  //     </p>
  //   ));
  //   return descHtml;
  // };
  // const children = page3.map((card, i) => (
  //   <div className="one-card" key={i.toString()}>
  //     {
  //       i === 0 ? (
  //         <img src={faqBgImg} className="faqBgImg" alt=""/>
  //       ) : null
  //     }
  //     <div className="card-content">
  //       <img src={card.img} alt="" />
  //       <p className="card-title">{card.title}</p>
  //       <div className="caed-detail">{renderDesc(card.descriptions)}</div>
  //     </div>
  //   </div>
  // ));

  const children1 = faq.map((item, i) => (
    <div onClick={() => {setCurrentTab(i)}} className={['faqTabItem', i===currentTab && 'currentTab'].join(' ')}  key={i.toString()}>
      {
        item.tabTitle
      }
    </div>
  ));

  const children2 = faq.map((item, i) => {
    if(i===currentTab){
      console.log(item,'item');
      const {DescriptionProblem} = item
      return (
        <div className="faqTabContent" >
          {
            DescriptionProblem.map((u,v)=> {
                return <div className='faqItem'>
                <div className='question'><span className='qTip'>Q</span>{u.question}</div>
                <div className='answerBox'>
                <span className='aTip'>A</span>
                <div>
                  {
                    u.answer.map((e,k)=> {
                      return <div className='answer'>{e}</div>
                    })
                  }
                </div>
                </div>
              </div>
              
            })
          }
        </div>
      )
    }else {
      return null
    }
  });

  const children3 = faq.map((item, i) => (
    <div onClick={() => {setCurrentTab(i)}} className={['faqTabItem', i===currentTab && 'currentTab'].join(' ')}  key={i.toString()}>
      {
        item.tabTitle
      }
    </div>
  ));

  const children4 = faq.map((item, i) => {
    if(i===currentTab){
      const {DescriptionProblem} = item

      return (
        <div className="faqTabContent" >
          {
            DescriptionProblem.map((u,v)=> {
                return <div className='faqItem'>
                <div className='question'><span className='qTip'>Q</span>{u.question}</div>
                <div className='answerBox'>
                <span className='aTip'>A</span>
                <div>
                  {
                    u.answer.map((e,k)=> {
                      return <div className='answer'>{e}</div>
                    })
                  }
                </div>
                </div>
              </div>
              
            })
          }
        </div>
      )
    }else {
      return null
    }
  });


  return (
    <div>
    <Header isMobile={isMobile} handleclick={homeJumpToSection} activeFAQ={true} className={"btn-white"}></Header>
    <section id="FAQ" className="page-wrapper page3 text-center">
      {
        !isMobile ? (
          <div>
            <p className="page3-title">{'FAQ'}</p>
            <p className="page3-subtitle">{'OKPeso’s team has compiled a list of common misgivings that they hope will help you.'}</p>
            {/* <QueueAnim
                type="bottom"
                className="page"
                key="page3"
                component={Row}
              >
              {children}
            </QueueAnim> */}

            <div className='faqPc'>
              <div className='faqTabs'>
                {children1}
                </div>
                {children2}
            </div>
            
          </div>
        ) : (
          <div className="page3-mobile-content">
            <img src={faqBgImg2} className="faqBgImg2" alt=""/>
            <img src={faqBgImg3} className="faqBgImg3" alt=""/>
            <img src={faqBgImg4} className="faqBgImg4" alt=""/>
            <p className="page3-title">{'FAQ'}</p>
            <p className="page3-subtitle">{'OKPeso’s team has compiled a list of common misgivings that they hope will help you.'}</p>
            {/* <div className="one-card">
              <img src={faqBgImg} className="faqBgImg" alt=""/>
              <div className="common-card">
                <img src={page3[0].img} alt="" />
                <p className="card-title">{page3[0].title}</p>
                <div className="card-detail">{renderDesc(page3[0].descriptions)}</div>
              </div>
            </div>
            <div className="one-card">
              <div className="common-card">
                <img src={page3[1].img} alt="" />
                <p className="card-title">{page3[1].title}</p>
                <div className="card-detail">{renderDesc(page3[1].descriptions)}</div>
              </div>
            </div>
            <div className="one-card">
              <div className="common-card last-card">
                <img src={page3[2].img} alt="" />
                <p className="card-title">{page3[2].title}</p>
                <div className="card-detail">{renderDesc(page3[2].descriptions)}</div>
              </div>
              
            </div> */}
            <div className='faqMobile'>
              <div className='faqTabsMobile'>
              {children3}
              </div>
              {children4}
            </div>
            {/* <div className="mobile-common-footer" style={{marginTop: 125}}>
              <p className="title">Follow Us</p>
              <div className="list-content">
                <a className="one-item" target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/RealOKPeso/">
                  <svg className="facebookIcon">
                    <use xlinkHref="#iconFacebook_icon"/>
                  </svg>
                  <span>Facebook</span>
                </a>
                <a className="one-item" style={{marginLeft: 29}} target="_blank" rel="noopener noreferrer" href="https://www.twitter.com/ok_peso">
                  <svg>
                    <use xlinkHref="#icontwitter_icon"/>
                  </svg>
                  <span>Twitter</span>
                </a>
                <a className="one-item" style={{marginLeft: 29}}  href="#/">
                  <svg>
                    <use xlinkHref="#iconyoutube-icon"/>
                  </svg>
                  <span>Youtube</span>
                </a>
              </div>
            </div>
            <div className="mobile-common-footer">
              <p className="title">Regulations</p>
              <div className="list-content regulation">
              <Router>
                <Link to="/creditAgreement" target="_blank" rel="noopener noreferrer" className="one-item" >
                    <svg className="wenjianIcon">
                      <use xlinkHref="#iconwenjian"/>
                    </svg>
                    <span>OKPeso Loan Agreement</span>
                </Link>
                <Link to="/privacyAgreement" target="_blank" rel="noopener noreferrer" className="one-item" >
                    <svg className="wenjianIcon">
                      <use xlinkHref="#iconwenjian"/>
                    </svg>
                    <span>Privacy Agreement</span>
                </Link>
                <Link to="/serviceAgreement" target="_blank" rel="noopener noreferrer" className="one-item" >
                    <svg className="wenjianIcon">
                      <use xlinkHref="#iconwenjian"/>
                    </svg>
                    <span>Service Agreement</span>
                </Link>
              </Router>
              </div>
            </div> */}
          </div>
        )
      }
    </section>
    <Footer key="footer" isMobile={isMobile} />
    </div>
  );
}
